<template lang="pug">
.FStripe(
  :style="style"
  :class="classes"
)
</template>

<style lang="stylus">
stripe($color, $angle, $opacity, $tilt)
  width 100%
  background-color $color
  transform translateY(-50%) skewY($angle) skewX($tilt)
  transform-origin var(--FStripe--transformOrigin)
  opacity $opacity
  position var(--FStripe--position, absolute)
  top calc(var(--FStripe--top) + var(--FStripe--verticalShift))
  z-index var(--FStripe--zIndex)

.FStripe--sized
  height var(--FStripe--height)

.FStripe--cover
  height calc(100% + var(--FStripe--height-offset) - var(--FStripe--verticalShift))

.FStripe
  stripe(
    var(--FStripe--color),
    var(--FStripe--angle),
    var(--FStripe--opacity),
    var(--FStripe--tilt),
    
  )
</style>

<script setup lang="ts">
import { sectionContentMaxWidth } from '@/config/sizes';
import { toRadians } from '@/lib/utils/angle';

export interface FStripeProps {
  /**
   * Color of the stripe
   */
  color?: Color;
  /**
   * Angle of the stripe
   */
  angle?: CssAngle;
  /**
   * Height of the stripe if not in "cover" mode
   */
  height?: string | number;
  /**
   * Top value. Default to 50%
   */
  top?: string | number;
  /**
   * Allow to shift the content vertically with an offset of the container height
   */
  verticalShift?: string | number;
  /**
   * Opacity of the stripe
   */
  opacity?: string | number;
  /**
   * Content container element
   */
  containerRef?: HTMLElement | null;
  /**
   * Custom z-index value for superposition purposes
   */
  zIndex?: string | number;
  /**
   * Relative positioning (instead of absolute). Sets transform origin to center
   */
  relative?: boolean;
  /**
   * Transform origin. If 'auto', processed internally to fit the content center
   */
  transformOrigin?: string | 'auto';
  /**
   * Horizontal distortion of the stripe, in an angle unit.
   */
  tilt?: CssAngle;
}

const props = withDefaults(defineProps<FStripeProps>(), {
  color: 'primary',
  height: 'auto',
  angle: '0deg',
  top: '50%',
  verticalShift: '0%',
  opacity: '1',
  containerRef: null,
  zIndex: -1,
  relative: false,
  transformOrigin: 'auto',
  tilt: '0deg',
});

const containerRef = computed<HTMLElement | null>(() => props.containerRef);
const { width: containerWidth, height: containerHeight } = useElementSize(
  containerRef,
  {
    width: sectionContentMaxWidth,
    height: 0,
  }
);
const { left: containerLeft, top: containerTop } =
  useElementBounding(containerRef);

/**
 * Wether the Stripe covers a container or has a set height
 */
const isCover = computed<boolean>(() => props.height === 'auto');

/**
 * The stripe's calculated height offset due to its angle
 */
const stripeHeightOffset = computed<string>(
  () =>
    /**
     * if the Stripe is covering some content, calculate the additional
     * height needed because of the angle of the stripe, otherwise return 0
     */
    (isCover.value
      ? containerWidth.value * Math.tan(Math.abs(toRadians(props.angle)))
      : '0') + 'px'
);

/**
 * Set the transform origin on the content center
 */
const stripeTransformOrigin = computed(() => {
  if (props.transformOrigin !== 'auto') return props.transformOrigin;
  const originX = props.relative
    ? 'center'
    : `${containerWidth.value / 2 + containerLeft.value}px`;
  const originY = props.relative
    ? 'center'
    : `${containerHeight.value / 2 + containerTop.value}px`;
  return `${originX} ${originY}`;
});

const classes = computed(() => ({
  'FStripe--cover': isCover.value,
  'FStripe--sized': !isCover.value,
}));

const style = computed(
  (): Style => ({
    '--FStripe--color': getCssColor(props.color),
    '--FStripe--angle': props.angle,
    '--FStripe--height': genSize(props.height),
    '--FStripe--top': genSize(props.top),
    '--FStripe--opacity': String(props.opacity),
    '--FStripe--height-offset': stripeHeightOffset.value,
    '--FStripe--zIndex': String(props.zIndex),
    '--FStripe--verticalShift': genSize(props.verticalShift),
    '--FStripe--transformOrigin': stripeTransformOrigin.value,
    '--FStripe--position': props.relative ? 'relative' : 'absolute',
    '--FStripe--tilt': props.tilt,
  })
);
</script>
